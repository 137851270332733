import { getImage } from 'gatsby-plugin-image'

import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import CallToAction from '~/components/call-to-actions/CallToAction'
import Service3DIconCard from '~/components/cards/Service3DIconCard'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import ClientVideoQuotes from '~/components/items/ClientVideoQuotes'
import DiscoverWorksTabs from '~/components/items/DiscoverWorksTabs'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import LazyHydrate from '~/components/shared/LazyHydrate'
import SubtractBlock from '~/components/shared/SubtractBlock'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import Anchors from '~/mock/services/web-application-development/Anchors'
import WebAppDevBenefits from '~/mock/services/web-application-development/WebAppDevBenefits'
import WebAppDevBusiness from '~/mock/services/web-application-development/WebAppDevBusiness'
import WebAppDevCost from '~/mock/services/web-application-development/WebAppDevCost'
import WebAppDevExperience from '~/mock/services/web-application-development/WebAppDevExperience'
import WebAppDevExpertise from '~/mock/services/web-application-development/WebAppDevExpertise'
import WebAppDevFAQ from '~/mock/services/web-application-development/WebAppDevFAQ'
import WebAppDevIndustries from '~/mock/services/web-application-development/WebAppDevIndustries'
import WebAppDevPractices from '~/mock/services/web-application-development/WebAppDevPractices'
import WebAppDevProcess from '~/mock/services/web-application-development/WebAppDevProcess'
import WebAppDevServices from '~/mock/services/web-application-development/WebAppDevServices'
import WebAppDevTabContent from '~/mock/services/web-application-development/WebAppDevTabContent'
import WebAppDevTabList from '~/mock/services/web-application-development/WebAppDevTabList'
import { fileToImageLikeData } from '~/utils'
import CompanyProjectsLogoSection from '~/views/Homepage/components/CompanyProjectsLogoSection'
import ServicesDevExpertiseCompany from '~/views/Services/components/ServicesDevExpertiseCompany'
import ServicesProcess from '~/views/Services/components/ServicesProcess'
import ServicesRequirementsSoftware from '~/views/Services/components/ServicesRequirementsSoftware'
import ServicesTechStackSection from '~/views/Services/components/ServicesTechStackSection'
import useWebAppDevelopmentStaticQuery from '~/views/Services/WebAppDevelopment/useWebAppDevelopmentStaticQuery'

import * as containerStyles from './WebAppDevelopment.module.scss'

const WebAppDevelopment = () => {
  const query = useWebAppDevelopmentStaticQuery()
  const webAppDevDevTabContent = WebAppDevTabContent()
  const clientPMK = getImage(fileToImageLikeData(query.clientPMK))

  return (
    <MainLayout scrollTop>
      <PrimeSectionServices
        titleTop="Web Application"
        title="Development Services"
        description="We are crafting unique web apps that stand out. Our skilled web developers deliver intuitive user interfaces and seamless functionality tailored to your needs."
        breadcrumbSecondLevel="Services"
        breadcrumbSecondLevelPath="/services/"
        breadcrumbCurrent="Web Application Development Services"
        breadcrumbCurrentPath="/services/web-application-development/"
      />

      <StickyNavigation anchors={Anchors} />

      <section
        id="services"
        className={containerStyles.webAppDevServiceSection}
      >
        <HeadSection
          position="center"
          title="Build your solution with our web app development services"
          description="Our specialists bring your ideas to life and transform them into dynamic, interactive web apps that drive engagement and deliver exceptional user experiences."
          descriptionLarge
        />
        <Service3DIconCard dataContent={WebAppDevServices} />
      </section>

      <section
        id="industries"
        className={containerStyles.webAppDevServiceIndustries}
      >
        <HeadSection
          position="center"
          title="Our web app development solutions for diverse industry sectors"
          description="Our team specializes in delivering tailored web app development services for various industry sectors. We understand businesses' unique challenges and opportunities in today's market area. Thus, our clients get innovative web apps that drive growth, enhance customer experiences, and optimize operations."
          titleLarge
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={WebAppDevIndustries} />
      </section>

      <CallToAction
        title="Need a cutting-edge web app?"
        subtitle="Let our experts bring your vision to life."
        classNameForGA="servicesCTA_1"
        linkName="Contact us"
        link="/contacts/"
      />

      <section
        id="business-type"
        className={containerStyles.webAppDevBusinesses}
      >
        <HeadSection
          position="center"
          title="Exploring the possibilities: web apps for every purpose"
          description="With our web application development services, businesses of all sizes and sectors can unlock their full potential in the marketing realm. Our expert team is committed to creating innovative and reliable apps that help you stay ahead in today's competitive landscape."
          titleLarge
          descriptionLarge
        />
        <ServicesIndustriesCard isColTwo dataContent={WebAppDevBusiness} />
      </section>

      <section className={containerStyles.webAppDevBenefits} id="benefits">
        <HeadSection
          position="center"
          title="Empower your business with the main benefits of web app development"
          description="Our web application development services offer many advantages that can revolutionize how businesses operate and engage with customers. So our clients can get powerful and accessible web apps."
          titleLarge
          descriptionLarge
        />

        <ServicesIconSmallCard isColSm dataContent={WebAppDevBenefits} />
        <SubtractBlock />
      </section>

      <section className={containerStyles.webAppDevProcessSection} id="process">
        <HeadSection
          position="center"
          title="Codica’s step-by-step guide to successful web app development"
          description="Our web application development process is characterized by a client-centric approach, where we prioritize your specific needs and objectives. We ensure your web application stands out, delights users, and drives your business forward."
          descriptionLarge
        />
        <ServicesProcess dataContent={WebAppDevProcess} />
      </section>

      <section
        id="tech-stack"
        className={containerStyles.webAppDevTechStackSection}
      >
        <HeadSection
          position="center"
          title="Advanced tech stack for exceptional web app development"
          description="Codica is at the forefront of web application development, harnessing the power of the best tech stack to deliver exceptional solutions that propel businesses to new heights. Our team of skilled developers prefers the latest technologies, frameworks, and practices to create robust, scalable, and innovative web apps that surpass client expectations."
          descriptionLarge
        />
        <ServicesTechStackSection />
      </section>

      <section className={containerStyles.webAppDevCostSection} id="costs">
        <HeadSection
          position="center"
          title="Tailored pricing plans to fit your web development needs"
          description="The Codica web application development company offers flexible and transparent pricing models that best aligns with clients' budget, project scope, and desired outcomes."
          descriptionLarge
        />
        <ServicesRequirementsSoftware dataContent={WebAppDevCost} />
      </section>

      <section
        id="practices"
        className={containerStyles.webAppDevPracticesSection}
      >
        <HeadSection
          position="center"
          title="Best practices for high-quality web app development we follow"
          description="At Codica, we adhere to best practices for high-quality web app development to ensure our clients receive top-notch solutions. By following these most promising approaches, our experts deliver exciting web app solutions that exceed our clients' expectations."
          descriptionLarge
        />
        <ServicesIconSmallCard dataContent={WebAppDevPractices} />
        <SubtractBlock />
      </section>

      <CallToAction
        title="Want a powerful web app that captivates users?"
        subtitle="Let's create a web masterpiece together."
        classNameForGA="servicesCTA_2"
        linkName="Talk to an expert"
        link="/contacts/"
      />

      <section className={containerStyles.webAppDevExperienceSection}>
        <HeadSection
          position="center"
          title="Our experience in web app development"
          description="With Codica’s web app development expertise, you can unlock the full potential of your business by leveraging innovative technology solutions that drive growth, efficiency, and customer satisfaction."
          titleLarge
          descriptionLarge
        />
        <ServicesIconSmallCard isBorder dataContent={WebAppDevExperience} />
      </section>

      <section className={containerStyles.webAppDevCaseSection} id="cases">
        <HeadSection position="left" title="Our case studies" />
        <DiscoverWorksTabs
          dataTabList={WebAppDevTabList}
          dataContent={webAppDevDevTabContent}
        />
      </section>

      <LazyHydrate whenVisible>
        <section className={containerStyles.webAppDevProjLogoSection}>
          <CompanyProjectsLogoSection isCenter />
        </section>
      </LazyHydrate>

      <section className={containerStyles.webAppDevExpertiseSection}>
        <HeadSection
          position="center"
          title="Trusted expertise: why Codica stands out in web app development"
          description="Codica is a trusted partner in the industry regarding web app development. We adhere to industry best practices, follow rigorous testing processes, and prioritize code quality to ensure our web apps are robust, scalable, and performant. By paying careful attention to detail, we create solutions that meet immediate needs and lay a solid foundation for future expansion and adaptability."
          titleLarge
          descriptionLarge
        />
        <ServicesDevExpertiseCompany dataContent={WebAppDevExpertise} />
      </section>

      <section className={containerStyles.webAppDevClientQuotes}>
        <HeadSection
          position="center"
          title="Satisfied clients speak"
          description="Codica is a leading web app development company with a stellar reputation for delivering top-quality solutions to businesses across various industries. Our clients highlight the company's unwavering dedication to understanding their unique business needs and providing tailored software solutions."
          descriptionLarge
        />
        <div className="container container-md">
          <ClientVideoQuotes
            name="David Watkins"
            position="Founder of PlanMyKids"
            location="USA"
            image={clientPMK}
            quote={[
              {
                text: '“The PlanMyKids journey started when my family struggled to plan our own kid’s activities. As busy parents, we were time-constrained, the information we needed was all over the place, and coordinating with family felt like it required a project management certification.”',
              },
            ]}
            link="kids-activities-service-marketplace"
            videoLink="https://www.youtube.com/embed/dzxMCDLghZQ"
          />
        </div>
      </section>

      <section id="free-quote">
        <PrimeContactFormSection
          title="Let’s build a dream web app solution together!"
          widthTitle="650px"
          formInfoClassName="formInfo__services"
          customSalesInfo
          customSalesInfoStepFour="4. Our web app specialists will analyze your requirements and suggest the best ways to bring your idea to life."
        />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={WebAppDevFAQ} />
      </section>
    </MainLayout>
  )
}

export default WebAppDevelopment
